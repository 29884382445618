import { SplitFactory } from '@splitsoftware/splitio'
import { applicationSplits, platformSplits } from '@/plugins/split/ActiveSplits'

const APPLICATION_KEY = 'segments'

/**
 * Split Manager is a stateful class to hold SplitIO clients
 * and all associated treatments
 */
export class SplitManager {
  /** @type {string} */
  _splitApiKey = process.env.VUE_APP_SPLIT_API_KEY

  /** @type {SplitIO.IClient} */
  _applicationSplitClient
  /** @type {SplitIO.IClient} */
  _platformSplitClient

  /** @type {Object<string, FeatureFlag>} */
  _treatments = {}

  constructor () {
    window.SplitManager = this
  }

  /**
   * Checks if a given split is enabled by name
   * @param {string} splitName
   * @return {boolean}
   */
  isSplitEnabled (splitName) {
    return this._treatments[splitName]?.isEnabled || false
  }

  /**
   * Create method for Platform SplitIO client
   * and initializes related treatments
   * @param {string} platformHash
   * @param {string} svpUtid
   * @return {Promise<void>}
   */
  async createPlatformClient (platformHash, svpUtid) {
    if (!this._platformSplitClient && platformSplits.length > 0) {
      this._platformSplitClient = this.initSplitClient(platformHash, platformSplits)
      this._platformSplitClient.on(this._platformSplitClient.Event.SDK_UPDATE, () => {
        this.applyPlatformTreatments(svpUtid)
      })
      await this._platformSplitClient.ready()
      console.debug('SplitIO Platform client initialized')
      this.applyPlatformTreatments(svpUtid)
    }
  }

  /**
   * @private
   * Fetches all treatments for Platform SplitIO client
   * @param {string} svpUtid
   */
  applyPlatformTreatments (svpUtid) {
    platformSplits.forEach(split => {
      const treatment = this._platformSplitClient.getTreatment(split.name, { utid: svpUtid })
      this._treatments[split.name] = split.applySplitTreatment(treatment)
    })
  }

  /**
   * Create method for Application SplitIO client
   * and initializes related treatments
   * @return {Promise<void>}
   */
  async createApplicationClient () {
    if (!this._applicationSplitClient && applicationSplits.length > 0) {
      this._applicationSplitClient = this.initSplitClient(APPLICATION_KEY, applicationSplits)
      this._applicationSplitClient.on(this._applicationSplitClient.Event.SDK_UPDATE, () => {
        this.applyApplicationTreatments()
      })
      await this._applicationSplitClient.ready()
      console.debug('SplitIO Application client initialized')
      this.applyApplicationTreatments()
    }
  }

  /**
   * @private
   * Fetches all treatments for Application SplitIO client
   */
  applyApplicationTreatments () {
    applicationSplits.forEach(split => {
      const treatment = this._applicationSplitClient.getTreatment(split.name)
      this._treatments[split.name] = split.applySplitTreatment(treatment)
    })
  }

  /**
   * Test
   * Deactivates the application split client
   */
  destroyApplicationClient () {
    this._applicationSplitClient?.destroy()
  }

  /**
   * Deactivates the platform split client
   */
  destroyPlatformClient () {
    this._platformSplitClient?.destroy()
  }

  /**
   * @private
   * Helper function to build SplitFactory SplitIO client
   * @param {string} key
   * @param {Array<FeatureFlag>} clientSplits
   * @return {SplitIO.IClient}
   */
  initSplitClient (key, clientSplits) {
    const factoryConfig = {
      core: {
        authorizationKey: this._splitApiKey,
        key
      },
      startup: {
        readyTimeout: 10
      },
      sync: {
        splitFilters: [{
          type: 'byName',
          values: clientSplits.map(split => split.name)
        }]
      }
    }

    if (this._splitApiKey === 'localhost') {
      factoryConfig.features = clientSplits.reduce((acc, split) => {
        acc[split.name] = split.localTreatment
        return acc
      }, {})
    }

    return SplitFactory(factoryConfig).client()
  }
}

/**
 * @type {SplitManager}
 */
export const SplitClient = new SplitManager()
