export const FeatureFlagValue = Object.freeze({
  ON: 'on',
  OFF: 'off'
})

export class FeatureFlag {
  /** @type {string} */
  #name
  /** @type {string} */
  #enabledValue
  /** @type {string} */
  #localTreatment
  /** @type {boolean} */
  #isEnabled

  constructor (name) {
    this.#name = name
    this.#enabledValue = FeatureFlagValue.ON
    this.#localTreatment = FeatureFlagValue.ON
  }

  static for (splitName) {
    if (typeof splitName === 'string') {
      return new FeatureFlag(splitName)
    }
    throw new TypeError('The FeatureFlag name must be a string')
  }

  withEnabledValue (enabledValue) {
    if (typeof enabledValue === 'string') {
      this.#enabledValue = enabledValue
      return this
    }
    throw new TypeError('The FeatureFlags enabledValue must be the string representation of the splitIO enabled value')
  }

  withLocalTreatment (localTreatment) {
    if (typeof localTreatment === 'string') {
      this.#localTreatment = localTreatment
      return this
    }
    throw new TypeError('The FeatureFlags localTreatment must be the string representation of the splitIO enabled value')
  }

  /**
   * Split name
   * @return {string}
   */
  get name () {
    return this.#name
  }

  /**
   * Expected on value to identify if the split is active
   * @return {string}
   */
  get enabledValue () {
    return this.#enabledValue
  }

  /**
   * When running the application locally during development
   * This value is used instead of the real splitIO data
   * @return {string}
   */
  get localTreatment () {
    return this.#localTreatment
  }

  /**
   * Expected on value to identify if the split is active
   * @return {boolean}
   */
  get isEnabled () {
    return this.#isEnabled
  }

  /**
   * Determines if the FeatureFlag is enabled based on provided split treatment
   * @param {string} splitTreatment
   * @return {FeatureFlag}
   */
  applySplitTreatment (splitTreatment) {
    this.#isEnabled = splitTreatment === this.#enabledValue
    return this
  }
}
