import { SplitClient } from '@/plugins/split/SplitClient'

(async () => {
  // Catch errors to prevent them from stopping the app from loading if client fails to init
  await SplitClient.createApplicationClient().catch(error => {
    console.error(error)
  })

  await import('./app')
})()
